<script lang="ts" setup>
import { computed } from "vue";

import type { HTMLProps } from "../types-utils";

const props = withDefaults(
	defineProps<
		{
			size?: number;
			autosize?: boolean;
			background?: string;
			color?: string;
			variant?: "default" | "skew" | "info" | "ribbon";
			modifiers?: Array<"outlined">;
		} & HTMLProps
	>(),
	{ variant: "default", size: 14, background: "var(--chimoio)", color: "var(--a-badge-default-color)", autosize: false }
);
const size = computed(() => (props.autosize ? "auto" : `${props.size}px`));
const background = computed(() => props.background);
const color = computed(() => props.color);
</script>

<template>
	<div :class="['badge', variant, ...(modifiers || [])].filter(Boolean)">
		<slot />
	</div>
</template>

<style>
:root {
	--a-badge-default-color: var(--cannes);
	--a-badge-default-radius: 50%;
	--a-badge-default-padding: 0;
	--a-badge-info-height: auto;
	--a-badge-info-line-height: 16px;
	--a-badge-info-padding: 2px 4px;
	--a-badge-info-radius: 4px;
	--a-badge-info-shadow: 0 11px 18px rgb(0 0 0 / 25%);
	--a-badge-info-color: var(--cocio);
	--a-badge-info-border-width: 0;
	--a-badge-info-border-color: transparent;
	--a-badge-skew-size: auto;
	--a-badge-skew-height: 20px;
	--a-badge-skew-padding: 0 10px 0 12px;
	--a-badge-skew-border-radius: 3px;
	--a-badge-skew-before-content: "";
}

.tao:root {
	--a-badge-default-color: var(--neutral);
	--a-badge-default-radius: 16px;
	--a-badge-default-padding: 2px 8px;
	--a-badge-skew-before-content: none;
	--a-badge-skew-padding: 0 10px;

	--a-badge-info-padding: 1px 4px;
	--a-badge-info-shadow: none;
	--a-badge-info-radius: 4px;
	--a-badge-info-line-height: 18px;
	--a-badge-info-color: var(--neutral);
	--a-badge-info-border-width: 1px;
	--a-badge-info-border-color: var(--a-badge-info-background, transparent);
}

.nlc:root {
	--a-badge-default-color: var(--cocio);
	--a-badge-info-line-height: 10px;
	--a-badge-default-radius: 0;
	--a-badge-skew-before-content: none;
	--a-badge-skew-padding: 0 10px;
	--a-badge-ribbon-background: var(--genoa);
}

.fortuneWheelz:root {
	--a-badge-info-padding: 2px 8px;
	--a-badge-info-line-height: 14px;
	--a-badge-info-shadow: none;
}

.funzCity:root {
	--a-badge-info-radius: 999px;
	--a-badge-info-padding: 0 4px;
}
</style>

<style lang="scss" scoped>
div {
	width: v-bind(size);
	height: v-bind(size);
	background: v-bind(background);
	background-size: contain;
	border-radius: var(--a-badge-default-radius);
	color: v-bind(color);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: var(--a-badge-default-padding);

	&.skew {
		width: v-bind(size);
		height: var(--a-badge-skew-height);
		display: inline-flex;
		border-radius: var(--a-badge-skew-border-radius);
		padding: var(--a-badge-skew-padding);
		line-height: 20px;
		box-shadow: 0 4px 2px rgba(0, 0, 0, 0.15);
		transform: skewX(-12deg);

		&::before {
			content: var(--a-badge-skew-before-content);
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			width: 4px;
			border-radius: 0 6px 6px 0;
			background-color: v-bind(background);
			transform: skewX(-6deg);
			transform-origin: bottom right;
		}

		+ .skew {
			border-radius: 0 3px 3px 0;
		}

		& > :deep(*) {
			transform: skewX(12deg);
		}
	}

	&.info {
		--a-badge-info-background: v-bind(background);
		--a-badge-info-border-color: v-bind(background);
		width: v-bind(size);
		height: var(--a-badge-info-height);
		display: inline-block;
		padding: var(--a-badge-info-padding);
		background: v-bind(background);
		box-shadow: var(--a-badge-info-shadow);
		border-radius: var(--a-badge-info-radius);
		color: var(--a-badge-info-color);
		line-height: var(--a-badge-info-line-height);
		border: var(--a-badge-info-border-width) solid;
		border-color: var(--a-badge-info-border-color);
		background-clip: padding-box;

		&.outlined {
			background-color: transparent !important;
			background-image: transparent !important;
			border-color: var(--a-badge-info-color);
		}
	}
	&.ribbon {
		background: var(--a-badge-ribbon-background);
		clip-path: polygon(0 0, 100% 0, 100% 100%, 50% calc(100% - 7px), 0 100%);
		padding-bottom: gutter(1);
	}
}
</style>
